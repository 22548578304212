import propertyTypes from './property-types';

export const MAX_KIDS = 8;
export const MIN_KIDS_AGE = 0;
export const MAX_KIDS_AGE = 17;
export const MIN_SEARCH_PRICE = 0;
export const MAX_MIN_SEARCH_PRICE = 1;
export const MAX_SEARCH_PRICE = 100000000;
export const ADULT_PREFIX = 'A';
export const KIDS_PREFIX = 'K';
export const DATE_TYPES_FIELD_NAME = 'datesType';
export const DISPLAYED_SEARCH_QUERIES = 3;
export const DISPLAYED_SEARCH_RESULT_FEATURES = 2;
export const DISPLAYED_RESULT_FEATURES = 1;
export const SUGGESTIONS_LIMIT = 5;
export const FIRST_SUGGESTIONS_INDEX = 0;
export const PROPERTIES_PER_PAGE = 10;
export const PROPERTY_OFFERS_TAB = 2;
export const PROPERTY_ROOMS_TAB = 1;
export const PROPERTY_DETAILS_TAB = 0;
export const DEFAULT_CURRENCY = 'USD';
export const DEFAULT_LOUNGE_OPTION_INDEX = 1;
export const MIN_ROOMS_STATEMENT = 2;
export const DEFAULT_PREV_SEARCH_VALUE = { value: {}, filters: {} };

export const MODAL_TIMEOUT_TIME = 10000;

export const ALGO_TIMEOUT = 11000;

export const FOCUS_DROPDOWN_OPTIONS = [
  {
    label: 'Leisure',
    value: 'LEISURE',
  },
  {
    label: 'Business',
    value: 'BUSINESS',
  },
  {
    label: 'Mix Business & Leisure',
    value: 'MIX_OF_LEISURE_AND_BUSINESS',
  },
];

export const AMBIANCE_DROPDOWN_VALUES = [
  {
    label: 'No preference',
    value: 'NO_PREFERENCE',
  },
  {
    label: 'City / Urban',
    value: 'CITY_OR_URBAN',
  },
  {
    label: 'Escape / Nature',
    value: 'ESCAPE_OR_NATURE',
  },
];

export const DEFAULT_AMBIENCE_VALUE = AMBIANCE_DROPDOWN_VALUES[0].value;

export const LOUNGE_SECTION_OPTIONS = [
  {
    label: 'Never',
    value: 'NEVER',
  },
  {
    label: 'Some time / Some guests',
    value: 'SOME_TIME',
  },
  {
    label: 'Frequently',
    value: 'FREQUENTLY',
  },
];

export const INSURANCE_OPTIONS = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const CONFIDENT_OPTIONS = [
  {
    label: 'Very confident',
    value: 'VERY_CONFIDENT',
  },
  {
    label: 'Fairly confident',
    value: 'FAIRLY_CONFIDENT',
  }, {
    label: 'Some uncertainty',
    value: 'SOME_UNCERTAINTY',
  },
];

export const LOYALTY_PROGRAM_NAMES = [
  'IHG Rewards club',
  'Marriott Bonvoy',
  'Le Club Accorhotels',
  'Hilton Honors',
  'World of Hyatt',
  'Radisson Rewards',
  'Shangri La Golden Circle',
  'Fans of M.O.',
  'Wyndham Rewards',
];

export const LIST_OF_PERKS = [
  'Complimentary breakfast',
  'Lounge access',
  'Room upgrade',
];

export const LIST_OF_PERKS_VALUES = [
  { title: 'complimentaryBreakfast', id: 1 },
  { title: 'loungeAccess', id: 2 },
  { title: 'roomUpgrade', id: 3 },
];

export const DEFAULT_PROFILE_NAME = {
  label: 'Default user profile',
  value: 'default',
};

export const REQUIRED_OPTIONS = [
  { title: 'Breakfast included', value: 'breakfastIncluded', id: 1 },
  { title: 'Late cancellation', value: 'lateCancellation', id: 2 },
  { title: 'Non-smoking rooms', value: 'nonSmokingRooms', id: 3 },
  { title: 'Pets allowed', value: 'petsAllowed', id: 4 },
];

export const PROPERTY_TYPE_OPTIONS = [
  {
    id: 1,
    title: 'Hotels, hostels or guesthouses',
    value: `${propertyTypes.C1_LUXURY_HOTEL} ${propertyTypes.C2_UPSCALE_HOTEL} ${propertyTypes.C3_MID_RANGE_AND_UPPER_MID_RANGE_HOTEL} ${propertyTypes.C4_ECONOMY_HOTEL_AND_GUESTHOUSES}`,
  },
  {
    id: 2,
    title: 'Hotels, resorts and glamping only',
    value: `${propertyTypes.C1_LUXURY_HOTEL} ${propertyTypes.C2_UPSCALE_HOTEL} ${propertyTypes.C3_MID_RANGE_AND_UPPER_MID_RANGE_HOTEL}`,
  },
  {
    id: 3,
    title: 'Bed & breakfast',
    value: propertyTypes.C12_BED_AND_BREAKFAST,
  },
  {
    id: 4,
    title: 'Villas and private apartments',
    value: `${propertyTypes.C7_VILLAS} ${propertyTypes.C8_PRIVATE_HOUSES} ${propertyTypes.C9_PRIVATE_APARTMENTS} ${propertyTypes.C10_PRIVATE_ROOMS}`,
  },
  {
    id: 5,
    title: 'Campings',
    value: propertyTypes.C11_CAMPING_AND_MOBILE_HOME,
  },
];

export const CAPACITY_OPTIONS = [
  { label: 'Optimize capacity', key: 'optimized' },
  { label: 'Maximize capacity', key: 'maximized' },
];

export const CAPACITY_OPTIONS_DEFAULT = 'optimized';

export const CAPACITY_OPTIONS_DESCRIPTIONS = [
  'This includes the use of additional bedding such as roll away bed and / or sofa bed',
  'This is the maximum authorized capacity as per the property policy and includes both additional bedding and children sharing large size bed with their parents',
];

export const EXECUTIVE_LOUNGE_INFO_TOOLTIP = 'How regularly will you take advantage of the following Executive lounge features – as an average across all guests';
export const LOYALTY_PROGRAMS_INFO_TOOLTIP = 'Select the perks you are entitled to under each of those loyalty programs';

export const LOYALTY_PROGRAM_KEYS = LOYALTY_PROGRAM_NAMES.map((name) => name.replace(/\W/g, ''));

export const LOYALTY_PROGRAM_KEY_NAMES = LOYALTY_PROGRAM_NAMES
  .map((name) => ({ [name.replace(/\W/g, '')]: name }))
  .reduce(((r, c) => Object.assign(r, c)), {});

export const LOYALTY_PROGRAMS_INITIAL_VALUES = LOYALTY_PROGRAM_KEYS
  .map((name) => ({ [name]: [] }));

export const LOYALTY_PROGRAMS_INITIAL_VALUES_MAPPED = Object
  .assign({}, ...LOYALTY_PROGRAMS_INITIAL_VALUES);

export const SEARCH_PRICE_TYPE = [
  {
    label: 'Total budget for stay',
    value: 'ENTIRE_STAY',
  },
  {
    label: 'Budget per night',
    value: 'PER_NIGHT',
  },
];

export const SEARCH_PRICE_DISPLAY_VALUES = Object.seal({
  ENTIRE_STAY: 'Total budget for stay',
  PER_NIGHT: 'Budget per night',
  PER_DAY: 'Budget per day',
  ENTIRE_TRIPE: 'Total budget for trip',
  ACTIVITY: 'per activity',
});

export const SEARCH_GENERAL_OPTIONS = [
  { title: 'Breakfast included', id: 1, value: 'doubleBeds' },
  { title: 'Refundable offers only', id: 2, value: 'twinBeds' },
  // { title: 'Wi - fi', id: 3, value: 'wifi' },
  // { title: 'Restaurant in property', id: 4, value: 'restaurantInProperty' },
  // { title: 'Parking', id: 5, value: 'parking' },
];

export const SEARCH_FACILITIES_OPTIONS = [
  { title: 'Wi-fi', id: 1, value: 'wifi' },
  { title: 'Restaurant / Café', id: 2, value: 'restaurantInProperty' },
  { title: 'Swimming Pool', id: 3, value: 'swimmingPool' },
  { title: 'Parking', id: 4, value: 'parking' },
  { title: 'Fitness center', id: 5, value: 'fitnessCenter' },
  { title: 'Lap pool', id: 6, value: 'lapPool' },
  // { title: 'Spa', id: 4, value: 'spa' },
  { title: 'Business center', id: 7, value: 'businessCenter' },
  { title: 'Laundry room', id: 8, value: 'laundryRoom' },
  { title: 'Storage room', id: 9, value: 'storageRoom' },
];

export const SEARCH_ACCESSIBILITY_OPTIONS = [
  { title: 'Wheelchair accessible', id: 1, value: 'wheelchairAccessible' },
  { title: 'Roll in shower', id: 2, value: 'rollInShower' },
  { title: 'Lift to floors', id: 3, value: 'liftToFloors' },
];

export const PERIODS_DROPDOWN_OPTIONS = [
  {
    label: '+/- 1 day',
    value: '1',
  }, {
    label: '+/- 3 days',
    value: '3',
  },
  {
    label: '+/- 5 days',
    value: '5',
  },
  {
    label: '+/- 7 days',
    value: '7',
  },

];

export const PERIODS_DROPDOWN_DISPLAY_VALUES = Object.seal({
  ONE: '+/- 1 day',
  THREE: '+/- 3 days',
  FIVE: '+/- 5 days',
  WEEK: '+/- 7 days',
});

export const SEARCH_FILTERS_MODAL_INITIAL_VALUES = {
  general: [
    // SEARCH_GENERAL_OPTIONS[0].value,
    // SEARCH_GENERAL_OPTIONS[1].value,
  ],
  facilities: [
    // SEARCH_FACILITIES_OPTIONS[0].value,
    // SEARCH_FACILITIES_OPTIONS[1].value,
  ],
  accessibility: [
    // SEARCH_ACCESSIBILITY_OPTIONS[0].value,
    // SEARCH_ACCESSIBILITY_OPTIONS[1].value,
    // SEARCH_ACCESSIBILITY_OPTIONS[2].value,
  ],
};

export const FILTERS_MODAL_FIELDS = [
  {
    label: 'offers',
    name: 'general',
    options: SEARCH_GENERAL_OPTIONS,
  },
  {
    label: 'facilities',
    name: 'facilities',
    options: SEARCH_FACILITIES_OPTIONS,
  },
  {
    label: 'accessibility',
    name: 'accessibility',
    options: SEARCH_ACCESSIBILITY_OPTIONS,
  },
];

export const SEARCH_PAGE_INITIAL_VALUES = {
  // new destination data
  countryId: null,
  regionId: null,
  cityId: null,
  districtId: null,
  locationName: '',

  adultGuests: 2,
  kidGuests: 0,
  checkin: '',
  checkout: '',
  focus: FOCUS_DROPDOWN_OPTIONS[0].value,
  // ambiance: DEFAULT_AMBIENCE_VALUE,
  profile: 'default',
  requiredOptions: [],
  propertyType: [
    propertyTypes.C1_LUXURY_HOTEL,
    propertyTypes.C2_UPSCALE_HOTEL,
    propertyTypes.C3_MID_RANGE_AND_UPPER_MID_RANGE_HOTEL,
    propertyTypes.C4_ECONOMY_HOTEL_AND_GUESTHOUSES,
    propertyTypes.C5_RESIDENCE_ART_UPSCALE,
    propertyTypes.C6_RESIDENCE_ART_STANDARD,
  ],
  ageOfAdults: ['', ''],
  kids: [],
  rooms: 1,
  freeBreakfastInLounge: LOUNGE_SECTION_OPTIONS[DEFAULT_LOUNGE_OPTION_INDEX].value,
  alcoholicDrinksInLounge: LOUNGE_SECTION_OPTIONS[DEFAULT_LOUNGE_OPTION_INDEX].value,
  foodAndNonAlcoholicDrinks: LOUNGE_SECTION_OPTIONS[DEFAULT_LOUNGE_OPTION_INDEX].value,
  // optimized: true,
  currency: DEFAULT_CURRENCY,
  // maximized: false,
  noRefund: true,
  minPrice: 100,
  maxPrice: 200,
  priceType: SEARCH_PRICE_TYPE[1].value,
  // flexPeriod: '',
  region: '',
  hotel: '',
  travelInsurance: false,
  confidentOnBooking: CONFIDENT_OPTIONS[1].value,
  [DATE_TYPES_FIELD_NAME]: 0,
  loyaltyPrograms: {
    ...LOYALTY_PROGRAMS_INITIAL_VALUES_MAPPED,
  },
};

export const SEARCH_RESULT_ROOM_OPTIONS = [
  { title: 'Standard double', id: 1 },
  { title: 'Lux Single', id: 2 },
  { title: 'Lux Double', id: 3 },
  { title: 'Appartment', id: 4 },
];

export const SEARCH_RESULTS_FILTERS = [
  {
    label: 'General',
    name: 'general',
    options: SEARCH_GENERAL_OPTIONS,
  },
  {
    label: 'Facilities',
    name: 'facilities',
    options: SEARCH_FACILITIES_OPTIONS,
  },
  {
    label: 'Accessibility',
    name: 'accessibility',
    options: SEARCH_ACCESSIBILITY_OPTIONS,
  },
];

export const SEARCH_RESULTS_ROOM_FILTERS = [
  {
    label: 'Room type',
    name: 'room',
    options: SEARCH_RESULT_ROOM_OPTIONS,
  },
];

export const SEARCH_RESULTS_FILTERS_INITIAL_VALUES = {
  general: [],
  facilities: [],
  accessibility: [],
};

export const SEARCH_RESULT_ROOM_FILTERS_INITIAL_VALUES = {
  room: [],
};

export const OFFERS_TAB_INDEX = 2;
export const SEARCH_RESULT_PROPERTY_DETAILS_TABS = ['Offers', 'Review', 'Info', 'Rooms'];
export const PROPERTY_DETAILS_TABS = ['Review', 'Info', 'Rooms'];

export const SEARCH_MOBILE_TABS = Object.freeze({
  PV_CARDS: 'PV_CARDS',
  PROPERTY: 'PROPERTY',
});

export const INITIAL_SEARCH_RESULT_FILTER_VALUES = {};

SEARCH_RESULTS_FILTERS
  .map(({ options }) => (options))
  .forEach((options) => {
    options.forEach(({ value }) => { INITIAL_SEARCH_RESULT_FILTER_VALUES[value] = false; });
  });

export const RESULT_CRUMBS_REQUIRED_FEATURES = {
  breakfastIncluded: 'Breakfast included',
  lateCancellation: 'Late cancellation',
  nonSmokingRooms: 'Non-smoking room',
  petsAllowed: 'Pets allowed',
};

export const PROFILE_KEYS = [
  'atmosphereAndDesign',
  'comfortAndService',
  'environmentalAndSocial',
  'facilitiesAndActivities',
  'foodAndBeverages',
  'importanceOfPrice',
  'locationAndAccess',
];

export const TOOLBAR_SEARCH_ENTITY_TYPES = {
  activity: 'ACTIVITY',
  property: 'PROPERTY',
};
