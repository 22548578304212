import { PROFILING_FULL } from './form-fields';

export const fullProfilingInitValues = {
  comfortAndService: PROFILING_FULL.comfort.initialValue,
  locationAndConvenience: PROFILING_FULL.location.initialValue,
  priceImportance: PROFILING_FULL.importance.initialValue,
  environmentalAndSocial: PROFILING_FULL.property.initialValue,
  facilitiesAndActivities: PROFILING_FULL.facilities.initialValue,
  foodAndBeverages: PROFILING_FULL.food.initialValue,
  atmosphereAndDesign: PROFILING_FULL.atmosphere.initialValue,
};

export const passwordInitialValues = {
  password: '',
  newPassword: '',
  confirmPassword: '',
};
