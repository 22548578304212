export default {
  C1_LUXURY_HOTEL: 'C1_LUXURY_HOTEL',
  C2_UPSCALE_HOTEL: 'C2_UPSCALE_HOTEL',
  C3_MID_RANGE_AND_UPPER_MID_RANGE_HOTEL: 'C3_MID_RANGE_AND_UPPER_MID_RANGE_HOTEL',
  C4_ECONOMY_HOTEL_AND_GUESTHOUSES: 'C4_ECONOMY_HOTEL_AND_GUESTHOUSES',
  C5_RESIDENCE_ART_UPSCALE: 'C5_RESIDENCE_ART_UPSCALE',
  C6_RESIDENCE_ART_STANDARD: 'C6_RESIDENCE_ART_STANDARD',
  C7_VILLAS: 'C7_VILLAS',
  C8_PRIVATE_HOUSES: 'C8_PRIVATE_HOUSES',
  C9_PRIVATE_APARTMENTS: 'C9_PRIVATE_APARTMENTS',
  C10_PRIVATE_ROOMS: 'C10_PRIVATE_ROOMS',
  C11_CAMPING_AND_MOBILE_HOME: 'C11_CAMPING_AND_MOBILE_HOME',
  C12_BED_AND_BREAKFAST: 'C12_BED_AND_BREAKFAST',
};
