/* eslint-disable max-len */
/* eslint-disable func-names,object-shorthand */
import * as Yup from 'yup';
import {
  object, string, number, array, date, ref,
} from 'yup';
import moment from 'moment';
import {
  EMAIL_INVALID,
  EMAIL_REQUIRED,
  PROPERTY_EDIT_DESCR,
  ROOM_TYPE_REQUIRED,
  ADULTS_REQUIRED,
  VALUES_MUST_BE_POSITIVE,
  SURFACE_REQUIRED,
  ROOM_DESCR_INVALID,
  DESCR_REQUIRED,
  SURFACE_INVALID,
  CAPACITY_MAX,
  ROOMS_CUSTOM_AMENITY_INVALID,
  ROOMS_CUSTOM_AMENITY_REQUIRED,
  ROOMS_PHOTOS_CREATE_MAX,
  ROOMS_PHOTOS_MIN,
  ROOMS_BED_TYPE_TITLE_REQUIRED,
  ROOMS_BED_TYPE_WIDTH_REQUIRED,
  ROOMS_BED_TYPE_WIDTH_MAX,
  ROOMS_PHOTOS_MAX,
  ROOM_TITLE_INVALID,
  MAX_CAP_SUM,
  PASSWORD_INVALID_TIP,
  PASSWORD_REQUIRED,
  CONFIRM_PASSWORD_DOESNT_MATCH,
  PHONE_REQUIRED,
  PHONE_INVALID,
  USER_FIRST_NAME_REQUIRED,
  USER_NAME_INVALID,
  CHECK_IN_DATE_REQUIRED,
  CHECK_OUT_DATE_REQUIRED,
  SPACES_ONLY,
  COMMENT_INVALID,
  KID_AGE_REQUIRED,
  USER_LAST_NAME_REQUIRED,
  TITLE_REQUIRED,
  ADULTS_AGE,
  DESTINATION_REQUIRED, FULL_NAME_INVALID_TIP,
} from './error-messages';
import {
  EMAIL_MAX,
  PROP_DESCR_MAX,
  REGEXP_NOSPACES,
  MAX_SURFACE,
  ROOM_DESCR_MAX,
  ROOM_MAX_PEOPLE,
  ROOM_CUSTOM_AMENITY_TITLE_LENGTH,
  MIN_PHOTO_PER_RESTAURAUNT,
  MAX_PHOTO_PER_LOAD,
  BED_WIDTH_MAX,
  MAX_PHOTO_PER_RESTAURAUNT,
  REGEXP_ONE_LETTER,
  REGEXP_ONE_CAPITAL,
  REGEXP_ONE_DIGIT,
  PWD_MIN,
  PWD_MAX,
  PHONE_MIN,
  COMMENT_MAX, FULLNAME_MIN, FULLNAME_MAX,
} from './validation';
import { AT_LEAST_ONE_FIELD, ROOM_GENERAL_INFO } from './form-fields';
import { fullProfilingInitValues } from './init-values';
import { MAX_KIDS, MAX_KIDS_AGE } from './search';
import { DEFAULT_FORMAT } from './formats';

export const emailValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required(EMAIL_REQUIRED)
    .email(EMAIL_INVALID)
    .max(EMAIL_MAX, EMAIL_INVALID),
});

export const accessibilityValidationSchema = object().shape({
  description: string()
    .matches(REGEXP_NOSPACES, PROPERTY_EDIT_DESCR)
    .max(PROP_DESCR_MAX),
});

export const roomGeneralSchema = object().shape({
  general: object().shape({
    main: object().shape({
      [ROOM_GENERAL_INFO.roomType.name]: string()
        .matches(REGEXP_NOSPACES, ROOM_TITLE_INVALID)
        .required(ROOM_TYPE_REQUIRED),
      [ROOM_GENERAL_INFO.roomDescr.name]: string()
        .required(DESCR_REQUIRED)
        .matches(REGEXP_NOSPACES, ROOM_DESCR_INVALID)
        .max(ROOM_DESCR_MAX, ROOM_DESCR_INVALID),
      [ROOM_GENERAL_INFO.roomSurface.name]: number()
        .required(SURFACE_REQUIRED)
        .max(MAX_SURFACE, SURFACE_INVALID)
        .positive(SURFACE_INVALID),
      [ROOM_GENERAL_INFO.roomDesignedCapacityForAdults.name]: number()
        .required(ADULTS_REQUIRED)
        .max(ROOM_MAX_PEOPLE, CAPACITY_MAX)
        .positive(VALUES_MUST_BE_POSITIVE),
      [ROOM_GENERAL_INFO.roomDesignedCapacityForKids.name]: number()
        .max(ROOM_MAX_PEOPLE, CAPACITY_MAX)
        .positive(VALUES_MUST_BE_POSITIVE),
      [ROOM_GENERAL_INFO.roomMaximumCapacityForAdults.name]: number()
        .required(ADULTS_REQUIRED)
        .max(ROOM_MAX_PEOPLE, CAPACITY_MAX)
        .positive(VALUES_MUST_BE_POSITIVE),
      [ROOM_GENERAL_INFO.roomMaximumCapacityForKids.name]: number()
        .max(ROOM_MAX_PEOPLE, CAPACITY_MAX)
        .positive(VALUES_MUST_BE_POSITIVE),
    }),
    [ROOM_GENERAL_INFO.maxCapacitySum.name]: number().test(
      'maxCapacitySumEqualed',
      MAX_CAP_SUM,
      function () {
        const maxCapAdults = this.parent.main[
          ROOM_GENERAL_INFO.roomMaximumCapacityForAdults.name
        ] || 0;
        const maxCapKids = this.parent.main[ROOM_GENERAL_INFO.roomMaximumCapacityForKids.name]
          || 0;

        const desCapAdults = this.parent.main[
          ROOM_GENERAL_INFO.roomDesignedCapacityForAdults.name
        ] || 0;
        const desCapKids = this.parent.main[
          ROOM_GENERAL_INFO.roomDesignedCapacityForKids.name
        ] || 0;

        return (
          ((maxCapAdults + maxCapKids >= desCapAdults + desCapKids))
          // (maxCapAdults >= desCapAdults + desCapKids)
          // && maxCapAdults >= desCapAdults
          // && maxCapKids > 0 && desCapKids > 0 ? maxCapKids >= desCapKids : true
        );
      },
    ),

    // [ROOM_GENERAL_INFO.optimizedCapacitySum.name]: number().test(
    //   'optCapacitySumLess',
    //   OPT_CAP_LESS_SUM,
    //   function () {
    //     // const maxCapAdults = this.parent.main[
    //     //   ROOM_GENERAL_INFO.roomMaximumCapacityForAdults.name
    //     // ] || 0;
    //     // const maxCapKids = this.parent.main[ROOM_GENERAL_INFO.roomMaximumCapacityForKids.name]
    //     //   || 0;

    //     // const optCapAdults = this.parent.main[
    //     //   ROOM_GENERAL_INFO.roomOptimizedCapacityForAdults.name
    //     // ] || 0;
    //     // const optCapKids = this.parent.main[
    //     //   ROOM_GENERAL_INFO.roomOptimizedCapacityForKids.name
    //     // ] || 0;

    //     return (
    //       // maxCapAdults + maxCapKids >= optCapAdults + optCapKids
    //       // && maxCapAdults >= optCapAdults
    //       // && maxCapKids >= optCapKids
    //     );
    //   },
    // ),
    // [ROOM_GENERAL_INFO.optimizedCapacitySum.name]: number().test(
    //   'optCapacitySumEqualed',
    //   OPT_CAP_MAX_SUM,
    //   function () {
    //     // const desCapAdults = this.parent.main[
    //     //   ROOM_GENERAL_INFO.roomDesignedCapacityForAdults.name
    //     // ] || 0;
    //     // const desCapKids = this.parent.main[
    //     //   ROOM_GENERAL_INFO.roomDesignedCapacityForKids.name
    //     // ] || 0;

    //     // const optCapAdults = this.parent.main[
    //     //   ROOM_GENERAL_INFO.roomOptimizedCapacityForAdults.name
    //     // ] || 0;
    //     // const optCapKids = this.parent.main[
    //     //   ROOM_GENERAL_INFO.roomOptimizedCapacityForKids.name
    //     // ] || 0;

    //     return (
    //       // desCapAdults + desCapKids <= optCapAdults + optCapKids
    //       // && desCapAdults <= optCapAdults
    //       // && desCapKids <= optCapKids
    //     );
    //   },
    // ),
    // [ROOM_GENERAL_INFO.maxCapacitySum.name]: number()
    //   .test(
    //     'maxCapacitySumEqualed',
    //     MAX_CAP_SUM,
    //     createRoomCapacityValidatorRule(
    //       ROOM_GENERAL_INFO.roomMaximumCapacityForAdults.name,
    //       ROOM_GENERAL_INFO.roomDesignedCapacityForAdults.name,
    //     ),
    //   )
    //   .test(
    //     'maxCapacitySumEqualed',
    //     MAX_CAP_SUM,
    //     createRoomCapacityValidatorRule(
    //       ROOM_GENERAL_INFO.roomMaximumCapacityForKids.name,
    //       ROOM_GENERAL_INFO.roomDesignedCapacityForKids.name,
    //     ),
    //   ),

    // [ROOM_GENERAL_INFO.optimizedCapacitySum.name]: number()
    //   .test(
    //     'optCapacitySumLess',
    //     OPT_CAP_LESS_SUM,
    //     createRoomCapacityValidatorRule(
    //       ROOM_GENERAL_INFO.roomMaximumCapacityForAdults.name,
    //       ROOM_GENERAL_INFO.roomOptimizedCapacityForAdults.name,
    //     ),
    //   )
    // .test(
    //   'optCapacitySumLess',
    //   OPT_CAP_LESS_SUM,
    //   createRoomCapacityValidatorRule(
    //     ROOM_GENERAL_INFO.roomMaximumCapacityForKids.name,
    //     ROOM_GENERAL_INFO.roomOptimizedCapacityForKids.name,
    //   ),
    // ),
    // [ROOM_GENERAL_INFO.optimizedCapacitySum.name]: number()
    //   .test(
    //     'optCapacitySumEqualed',
    //     OPT_CAP_MAX_SUM,
    //     createRoomCapacityValidatorRule(
    //       ROOM_GENERAL_INFO.roomOptimizedCapacityForAdults.name,
    //       ROOM_GENERAL_INFO.roomDesignedCapacityForAdults.name,
    //     ),
    //   )
    //   .test(
    //     'optCapacitySumEqualed',
    //     OPT_CAP_MAX_SUM,
    //     createRoomCapacityValidatorRule(
    //       ROOM_GENERAL_INFO.roomOptimizedCapacityForKids.name,
    //       ROOM_GENERAL_INFO.roomDesignedCapacityForKids.name,
    //     ),
    //   ),
  }),
});

// ROOMS_CUSTOM_AMENITY_INVALID
export const roomCustomAmenitySchema = object().shape({
  title: string()
    .required(ROOMS_CUSTOM_AMENITY_REQUIRED)
    .matches(REGEXP_NOSPACES, ROOMS_CUSTOM_AMENITY_INVALID)
    .max(ROOM_CUSTOM_AMENITY_TITLE_LENGTH, ROOMS_CUSTOM_AMENITY_INVALID),
});

export const bedTypeSchema = object().shape({
  title: string().required(ROOMS_BED_TYPE_TITLE_REQUIRED),
  bedWidth: number()
    .required(ROOMS_BED_TYPE_WIDTH_REQUIRED)
    .max(BED_WIDTH_MAX, ROOMS_BED_TYPE_WIDTH_MAX)
    .positive(ROOMS_BED_TYPE_WIDTH_MAX),
});

export const bedTypeConfigSchema = object().shape({
  bedTypes: array().of(bedTypeSchema),
});

export const roomPhotosSchema = object().shape({
  photos: array()
    .required(ROOMS_PHOTOS_MIN)
    .max(MAX_PHOTO_PER_LOAD, ROOMS_PHOTOS_CREATE_MAX),
});

export const roomEditPhotosSchema = object().shape({
  photos: array()
    .max(MAX_PHOTO_PER_RESTAURAUNT, ROOMS_PHOTOS_MAX)
    .min(MIN_PHOTO_PER_RESTAURAUNT, ROOMS_PHOTOS_MIN),
});

export const roomAmenitiesSchema = object().shape({
  amenities: object().shape({
    custom: array().of(roomCustomAmenitySchema),
  }),
});

export const roomBedConfigSchema = object().shape({
  bedConfigurations: array().of(bedTypeConfigSchema),
});

export const roomGeneralValidationSchema = object()
  .shape({
    photos: array()
      .min(MIN_PHOTO_PER_RESTAURAUNT, ROOMS_PHOTOS_MIN)
      .max(MAX_PHOTO_PER_LOAD, ROOMS_PHOTOS_CREATE_MAX),
    amenities: object().shape({
      custom: array().of(roomCustomAmenitySchema),
    }),
    bedConfigurations: array().of(bedTypeConfigSchema),
  })
  .concat(roomGeneralSchema);

export const passwordValidationSchema = string()
  .required()
  .matches(REGEXP_NOSPACES)
  .matches(REGEXP_ONE_LETTER)
  .matches(REGEXP_ONE_CAPITAL)
  .matches(REGEXP_ONE_DIGIT)
  .min(PWD_MIN)
  .max(PWD_MAX);

export const fullNamedValidationSchema = string()
  .required()
  .min(FULLNAME_MIN, FULL_NAME_INVALID_TIP)
  .max(FULLNAME_MAX, FULL_NAME_INVALID_TIP);

export const countryValidationSchema = string()
  .required();

export const passwordValidationSchemaWithError = string()
  .required(PASSWORD_REQUIRED)
  .matches(REGEXP_NOSPACES, PASSWORD_INVALID_TIP)
  .matches(REGEXP_ONE_LETTER, PASSWORD_INVALID_TIP)
  .matches(REGEXP_ONE_CAPITAL, PASSWORD_INVALID_TIP)
  .matches(REGEXP_ONE_DIGIT, PASSWORD_INVALID_TIP)
  .min(PWD_MIN, PASSWORD_INVALID_TIP)
  .max(PWD_MAX, PASSWORD_INVALID_TIP);

export const passwordValidationSchemaCustomError = string()
  .required(PASSWORD_REQUIRED)
  .matches(REGEXP_NOSPACES, CONFIRM_PASSWORD_DOESNT_MATCH)
  .matches(REGEXP_ONE_LETTER, CONFIRM_PASSWORD_DOESNT_MATCH)
  .matches(REGEXP_ONE_CAPITAL, CONFIRM_PASSWORD_DOESNT_MATCH)
  .matches(REGEXP_ONE_DIGIT, CONFIRM_PASSWORD_DOESNT_MATCH)
  .min(PWD_MIN, CONFIRM_PASSWORD_DOESNT_MATCH)
  .max(PWD_MAX, CONFIRM_PASSWORD_DOESNT_MATCH);

export const fullProfilingValidationSchema = object({
  comfortAndService: Yup.string(),
  locationAndConvenience: Yup.string(),
  priceImportance: Yup.string(),
  environmentalAndSocial: Yup.string(),
  facilitiesAndActivities: Yup.string(),
  foodAndBeverages: Yup.string(),
  atmosphereAndDesign: Yup.string(),
  atLeastOne: Yup.bool().when(Object.keys(fullProfilingInitValues), {
    is: (
      comfortAndService,
      locationAndConvenience,
      priceImportance,
      environmentalAndSocial,
      facilitiesAndActivities,
      foodAndBeverages,
      atmosphereAndDesign,
    ) => !(
      comfortAndService
        || locationAndConvenience
        || priceImportance
        || environmentalAndSocial
        || facilitiesAndActivities
        || foodAndBeverages
        || atmosphereAndDesign
    ),
    then: Yup.bool().required(AT_LEAST_ONE_FIELD.error),
    otherwise: Yup.bool(),
  }),
});

export const customerPersonalInfoBaseValidationSchema = object().shape({
  firstName: string()
    .required(USER_FIRST_NAME_REQUIRED)
    .matches(REGEXP_NOSPACES, USER_NAME_INVALID),
  lastName: string()
    .required(USER_LAST_NAME_REQUIRED)
    .matches(REGEXP_NOSPACES, USER_NAME_INVALID),
  title: string().required(TITLE_REQUIRED),
});

export const phoneValidationSchema = object().shape({
  phone: string().required(PHONE_REQUIRED).min(PHONE_MIN, PHONE_INVALID),
});

export const bookingBaseValidationSchema = object()
  .shape({
    arrivalTime: string().optional(),
    comment: string()
      .max(COMMENT_MAX, COMMENT_INVALID)
      .matches(REGEXP_NOSPACES, SPACES_ONLY),
    startDate: date().required(CHECK_IN_DATE_REQUIRED),
    endDate: date()
      .test({
        name: 'MinEndDate',
        test: function (value) {
          return this.parent.startDate >= value
            ? this.createError({
              message: `Check out date must be late than ${moment(
                this.parent.startDate,
              ).format(DEFAULT_FORMAT)}`,
              path: 'endDate',
            })
            : true;
        },
      })
      .required(CHECK_OUT_DATE_REQUIRED),
  })
  .concat(emailValidationSchema)
  .concat(customerPersonalInfoBaseValidationSchema);

export const ageOfKidsValidationSchema = object().shape({
  kidGuests: number().max(MAX_KIDS),
  ageOfKids: array().of(number().max(MAX_KIDS_AGE).required(KID_AGE_REQUIRED)),
});

export const searchAgeOfKidsValidationSchema = object().shape({
  kidGuests: number().max(MAX_KIDS),
  kids: array().of(number().max(MAX_KIDS_AGE).required(KID_AGE_REQUIRED)),
});

export const landingSearchAgeOfKidsValidationSchema = object().shape({
  kids: number().max(MAX_KIDS),
  ageOfKids: array().when('kids', {
    is: (kids) => kids > 0,
    then: array().of(number().max(MAX_KIDS_AGE).required(KID_AGE_REQUIRED)),
  }),
});

export const budgetSchema = object().shape({
  minPrice: number()
    .required('Min. price is required')
    .max(ref('maxPrice'), ' '),
  maxPrice: number()
    .required('Max. price is required')
    .min(ref('minPrice'), "Max. price can't be less than min. price"),
});

export const activitySearchSchema = object().shape({
  ageOfKids: array().of(
    number().min(0).max(MAX_KIDS_AGE).required(KID_AGE_REQUIRED),
  ),
  ageOfAdults: array().of(
    number()
      .min(MAX_KIDS_AGE + 1, ADULTS_AGE)
      .max(99)
      .optional(),
  ),
  locationName: string().nullable().required(DESTINATION_REQUIRED),
}).concat(budgetSchema);
