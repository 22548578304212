import React, { useState } from 'react';
import { Input } from '../Input/Input';
import EyeHide from '../../../assets/img/icons/ic-eye-show.svg';
import Eye from '../../../assets/img/icons/ic-eye-hide.svg';
import classes from './InputPassword.module.scss';

const InputPassword = ({ name, placeholder, hideError }) => {
  const [showPassword, setShowPassword] = useState('password');

  const toggleShowPassword = () => setShowPassword((prevState) => (prevState === 'password' ? 'text' : 'password'));

  return (
    <div className={classes.inputContainer}>
      <Input
        hideError={hideError}
        type={showPassword}
        inputWrapperStyles={classes.inputWrapper}
        name={name}
        placeholder={placeholder}
      />
      <EyeHide
        onClick={toggleShowPassword}
        className={`${classes.passwordIcon} ${
          showPassword === 'password' ? classes.active : ''
        }`}
      />
      <Eye
        onClick={toggleShowPassword}
        className={`${classes.passwordIcon} ${
          showPassword === 'text' ? classes.active : ''
        }`}
      />
    </div>
  );
};

InputPassword.defaultProps = {
  hideError: true,
};

export default InputPassword;
